import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./style.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);
